import React, { useState, useEffect } from 'react';

import { Line } from "react-chartjs-2";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
} from "reactstrap";

function SubDetailComponent(props) {

  const [dataW, setDataW] = useState([]);
  const [dataT, setDataT] = useState([]);
  const [total, setTotal] = useState([]);
  const [label, setLabel] = useState([]);

  let chart1_2_options = {
    maintainAspectRatio: false,
    legend: {
      display: false
    },
    tooltips: {
      backgroundColor: "#f5f5f5",
      titleFontColor: "#333",
      bodyFontColor: "#666",
      bodySpacing: 4,
      xPadding: 12,
      mode: "nearest",
      intersect: 0,
      position: "nearest"
    },
    responsive: true,
    scales: {
      yAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.0)",
            zeroLineColor: "transparent"
          },
          ticks: {
            suggestedMin: 60,
            suggestedMax: 125,
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }
      ],
      xAxes: [
        {
          barPercentage: 1.6,
          gridLines: {
            drawBorder: false,
            color: "rgba(29,140,248,0.1)",
            zeroLineColor: "transparent"
          },
          ticks: {
            padding: 20,
            fontColor: "#9a9a9a"
          }
        }
      ]
    }
  };

  let chartExample1 = {
    data1: (canvas) => {
      let ctx = canvas.getContext("2d");

      let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

      gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
      gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
      gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

      return {
        labels: label,
        datasets: [
          {
            label: "Transporter Onboarded",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#1f8ef1",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#1f8ef1",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#1f8ef1",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: dataT
          },
          {
            label: "Warehouses Onboarded",
            fill: true,
            backgroundColor: gradientStroke,
            borderColor: "#3CF11F",
            borderWidth: 2,
            borderDash: [],
            borderDashOffset: 0.0,
            pointBackgroundColor: "#3CF11F",
            pointBorderColor: "rgba(255,255,255,0)",
            pointHoverBackgroundColor: "#3CF11F",
            pointBorderWidth: 20,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 15,
            pointRadius: 4,
            data: dataW
          }
        ]
      };
    },
    options: chart1_2_options
  };

  useEffect(() => {
    const getBusinesssStats = async () => {
      
      //stop loading when data is fetched
    }
    getBusinesssStats()
  }, [])

  return (
    <>
      <Card className="card-chart">
        <CardHeader>
          <h5 className="card-category">Sub Components</h5>
          <CardTitle tag="h3">
            <i className="tim-icons icon-bell-55 text-info" /> {total }
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div className="chart-area">
            <Line
              data={chartExample1["data1"]}
              options={chartExample1.options}
            />
          </div>
        </CardBody>
      </Card>

    </>
  );
};

export default SubDetailComponent;
