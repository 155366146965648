import React, { useState, useEffect } from 'react';
import { ThreeCircles } from 'react-loader-spinner'
import NotificationAlert from "react-notification-alert";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "../../assets/css/black-dashboard-react.css"
import { firestoreKeys } from '../../utils/constants';
import { db } from "../../services/firebase";
import { collection, query, getDocs, addDoc, doc, updateDoc } from "firebase/firestore";
import { DataGrid } from '@mui/x-data-grid';
import Checkbox from '@mui/material/Checkbox';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

export default function Banners({ user }) {
  const [IsLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([])
  const [showUpdate, setShowUpdate] = useState(false);
  const handleCloseUpdate = () => setShowUpdate(false);
  // Update Form
  const [bannerToUpdate, setBannerToUpdate]  = useState("");
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("")
  // Add form
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [newTitle, setNewTitle] = useState("");
  const [newMessage, setNewMessage] = useState("");


  const notificationAlertRef = React.useRef(null);
  const notify = (place, message, type) => {
    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const addItem = async () => {

    let dataToSend = {
      "title": newTitle,
      "message": newMessage,
      "active": false
    }
    const docRef = await addDoc(collection(db, firestoreKeys.BANNERS), dataToSend);
    console.log("Document written with ID: ", docRef.id);
    if (docRef.id === undefined) {
      notify("tl", <h1>Added banner failed</h1>, "danger")
    } else {
      const tmpArray = [...items]
      const item = dataToSend
      item.id = docRef.id
      Object.preventExtensions(item);
      tmpArray.push(item)


      setItems(tmpArray)
      notify("tl", <p>Added banner</p>, "success")
    }
    setShow(false)

  };

  const updateBanner = async () => {
    const docRef = doc(db, firestoreKeys.BANNERS, bannerToUpdate.id);
    let updatedFields = { title: title, message: message }
    updateDoc(docRef, updatedFields)
      .then(docRef => {
        const tmpArray = items
        let indexToUpdate = tmpArray.find(item => item.id === bannerToUpdate.id);
        indexToUpdate.title = title
        indexToUpdate.message = message
        setItems(tmpArray)
        setShowUpdate(false)
      })
      .catch(error => {
        console.log(error);
        notify("tl", <p>{error}</p>, "danger")
        setShowUpdate(false)
      })
  }

  const editBanner = async (banner) => {
    setBannerToUpdate(banner)
    setTitle(banner.title)
    setMessage(banner.message)
    setShowUpdate(true)
  };

  const activateBanner = async (id, activate) => {
    setIsLoaded(false)
    const docRef = doc(db, firestoreKeys.BANNERS, id);
    let updatedFields = { active: activate }
    const date = Date()
    if (activate) {
      updatedFields.datePublished = date
    }
    updateDoc(docRef, updatedFields)
      .then(docRef => {
        const tmpArray = items
        let indexToUpdate = tmpArray.find(item => item.id === id);
        indexToUpdate.active = activate;
        if (activate) {
          updatedFields.datePublished = date
        }
        setItems(tmpArray)
        setIsLoaded(true)
      })
      .catch(error => {
        console.log(error);
        notify("tl", <p>error</p>, "danger")
        setIsLoaded(true)
      })

  };



  useEffect(() => {
    const getContacts = async () => {
      const tmpArray = []
      const querySnapshot = await getDocs(query(collection(db, firestoreKeys.BANNERS)));
      querySnapshot.forEach((doc) => {
        let item = doc.data()
        item.id = doc.id
        tmpArray.push(item)
      });
      setItems(tmpArray)
      console.log(items)
      setIsLoaded(true)
    }
    getContacts()
  }, [])

  const renderDetailsButton = (params) => {
    return (
      <>
        <Button
          variant="primary"
          color="primary"
          size="small"
          style={{ marginLeft: 16 }}
          onClick={() => {
            editBanner(params.row)
          }}
        >
          Info
        </Button>
        {params.row.active ? (
          <Button
            variant="primary"
            color="primary"
            size="small"
            onClick={() => {
              activateBanner(params.row.id, false)
            }}
          >
            Deactivate
          </Button>
        ) : (
          <Button
            variant="primary"
            color="primary"
            size="small"
            onClick={() => {
              activateBanner(params.row.id, true)
            }}
          >
            Activate
          </Button>
        )}

      </>
    )
  }


  const ActiveCheckBox = (params) => {
    return (
      <>
        {params.row.active ? (
          <Checkbox defaultChecked />
        ) : (
          <Checkbox />
        )

        }


      </>
    )
  }

  const columns = [
    {
      field: 'Title',
      headerName: 'Title',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      valueGetter: (params) =>
        `${params.row.title || ''}`,
    },
    {
      field: 'active',
      headerName: 'Active',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      renderCell: ActiveCheckBox,
      disableClickEventBubbling: true,
    },
    {
      field: 'datePublished',
      headerName: 'Date Last Activated',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      valueGetter: (params) =>
        `${params.row.datePublished || ''}`,
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: true,
    },
  ];



  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <>
          <>
            <div className="content">
              <Row>

                <Col md="12">
                  <Card className="card-plain">
                    <CardHeader>
                      <CardTitle tag="h4" style={{fontSize: "1.2rem"}}>Banners</CardTitle>
                      <p className="category" style={{ fontSize: "0.8rem"}}>Messages to notify users of issues. &nbsp;<a href="#" onClick={() => setShow(true)}><i className="tim-icons icon-simple-add" /></a></p>
                    </CardHeader>
                    <CardBody>
                      <div style={{ height: 400, width: '100%' }}>
                        <DataGrid
                          rows={items}
                          columns={columns}
                          pageSize={5}
                          rowsPerPageOptions={[5]}
                          style={{}}
                          className="text-cente tablesorter"
                          getRowId={(row) => row.id}

                        /> </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>

            {/* Create modal */}
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Create Contact</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Title for the contact"
                      autoFocus
                      onChange={(e) => setNewTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} placeholder="Message to show users" onChange={(e) => setNewMessage(e.target.value)} />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={addItem}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
            {/* Update modal */}
            <Modal show={showUpdate} onHide={handleCloseUpdate}>
              <Modal.Header>
                <Modal.Title>Update Contact</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group >
                    <Form.Label>ID</Form.Label>
                    <Form.Control
                      type="id"
                      autoFocus
                      readOnly
                      value={bannerToUpdate.id}
                    />
                  </Form.Group>
                  <Form.Group >
                    <Form.Label>Last published</Form.Label>
                    <Form.Control
                      type="datePublished"
                      autoFocus
                      value={bannerToUpdate.datePublished}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group >
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      autoFocus
                      value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={3} value={message}
                     onChange={(e) => setMessage(e.target.value)} 
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseUpdate} style={{ marginBottom: "3vh", marginLeft: "2vw"}}>
                  Close
                </Button>
                <Button variant="primary" onClick={updateBanner} style={{ marginBottom: "3vh", marginRight: "2vw"}}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </>



        </>)
        : (
          <div className="content">
            <Row>

              <Col md="12">
                <Card className="card-plain">
                  <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                  }}>
                    <ThreeCircles
                      height="100"
                      width="100"
                      color="#9753f7"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="three-circles-rotating"
                      outerCircleColor=""
                      innerCircleColor="grey"
                      middleCircleColor=""
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>


        )
      }

    </>
  );
}

