import React, { useState, useEffect } from 'react';
import { ThreeCircles } from 'react-loader-spinner'
import { Input, IconButton, InputAdornment } from '@mui/material';
import "../../assets/css/black-dashboard-react.css"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {AuthContext } from "../../contexts/AuthContext"

import { firestoreKeys } from '../../utils/constants';
import { db } from "../../services/firebase";
import { collection,addDoc } from "firebase/firestore";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

export default function Developer({ props }) {

  const {user, setUser} = React.useContext(AuthContext)
  const [IsLoaded, setIsLoaded] = useState(true);

  const [values, setValues] = React.useState({
    password: user.accessToken,
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const getDetails = async () => {
      console.log(user)
      let dataToSend = {
        "date": new Date(),
        "actions": "access_token",
        "user": user.email
      }
      const docRef = await addDoc(collection(db, firestoreKeys.AUDIT_TRAIL), dataToSend);
    }
    getDetails()
  }, [])


  return (
    <>

      {IsLoaded ? (
        <>
          <>
            <div className="content">
              <Row>

                <Col md="12">
                  <Card className="card-plain">
                    <CardHeader>
                      <CardTitle tag="h4" style={{ fontSize: "1.2rem" }}>Developer</CardTitle>
                      <p className="category" style={{ fontSize: "0.8rem" }}>Developers to get their tokens</p>
                    </CardHeader>
                    <CardBody>
                      <div style={{ height: 400, width: '100%' }}>
                        <Input
                        style={ values.showPassword ?  {width: '90%' } : {width: '60%' }}
                          type={values.showPassword ? "text" : "password"}
                          value={values.password}
                          multiline={values.showPassword}
                          rows={values.showPassword ? 12 : 1}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {values.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </>



        </>)
        : (
          <div className="content">
            <Row>

              <Col md="12">
                <Card className="card-plain">
                  <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                  }}>
                    <ThreeCircles
                      height="100"
                      width="100"
                      color="#9753f7"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="three-circles-rotating"
                      outerCircleColor=""
                      innerCircleColor="grey"
                      middleCircleColor=""
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>


        )
      }

    </>
  );
}

