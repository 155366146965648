
import React, { useEffect, useState } from "react";
import { Line, Bar } from "react-chartjs-2";
import { ThreeCircles } from 'react-loader-spinner'

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartExample3,
  chartExample4
} from "variables/charts.js";
import SubDetailComponent from "components/Dashboard/SubDetails";
import { UserOutlined, FormOutlined, TeamOutlined, InboxOutlined, ExclamationCircleOutlined, EnvironmentOutlined, DollarCircleOutlined } from '@ant-design/icons';
import StatisticCard from "../StatisticCard";
import { firestoreKeys } from '../../utils/constants';
import { db } from "../../services/firebase";
import { collection, getCountFromServer, query, where } from "firebase/firestore";

function Dashboard(props) {

  const [users, setUsers] = React.useState(undefined)
  const [applications, setApplications] = React.useState(undefined)
  const [hosts, setHosts] = React.useState(undefined)
  const [reports, setReports] = React.useState(undefined)
  const [spots, setSpots] = React.useState(undefined)
  const [bookings, setBookings] = React.useState(undefined)
  const [feedback, setFeedback] = React.useState(undefined)
  const [contactUs, setContactUs] = React.useState(undefined)

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const getContacts = async () => {
      const applications = await getCountFromServer(query(collection(db, firestoreKeys.SPOTS), where('admin.state', '==', 'pending')));
      setApplications(applications.data().count)

      const bookings = await getCountFromServer(collection(db, firestoreKeys.BOOKINGS));
      setBookings(bookings.data().count)

      const users = await getCountFromServer(collection(db, firestoreKeys.USERS));
      setUsers(users.data().count)

      const hosts = await getCountFromServer(query(collection(db, firestoreKeys.USERS), where('permissions.isHost', '==', true)));
      console.log(hosts.data().count)
      setHosts(hosts.data().count)

      const reports = await getCountFromServer(query(collection(db, firestoreKeys.REPORTS), where('isResolved', '==', false)));
      setReports(reports.data().count)

      const spots = await getCountFromServer(collection(db, firestoreKeys.SPOTS));
      setSpots(spots.data().count)

      const feedback = await getCountFromServer(query(collection(db, firestoreKeys.FEEDBACK), where('isAcknowledged', '==', false)));
      setFeedback(feedback.data().count)

      const contactUs = await getCountFromServer(query(collection(db, firestoreKeys.CONTACTUS), where('isAcknowledged', '==', false)));
      setContactUs(contactUs.data().count)

      setIsLoaded(true)
    }
    getContacts()
  }, [])

  return (
    <>


      {
        isLoaded ? (
          <div className="content">
            <Row>
              <Col xs="12">
                <Card className="card-chart">
                  <CardHeader>
                    <Row>
                      <Col className="text-left" sm="6">
                        <h5 className="card-category" style={{ textDecoration: "underline", textDecorationColor: "#df4ecc", textDecorationThickness: "0.1vw", fontFamily: "Poppins", fontSize: "0.9rem"}}>Parkshare Stats</h5>
                        <CardTitle tag="h3">Overview</CardTitle>
                      </Col>

                    </Row>
                  </CardHeader>
                  <CardBody style={{ marginLeft: "2vw"}}>
                    <Row gutter={16}>
                      <StatisticCard label="Applications" value={applications} icon={<FormOutlined />} />
                      <StatisticCard label="Users" value={users} icon={<UserOutlined />} />
                      <StatisticCard label="Hosts" value={hosts} icon={<TeamOutlined />} />
                      <StatisticCard label="Spots" value={spots} icon={<EnvironmentOutlined />} />

                      <StatisticCard label="Bookings" value={bookings} icon={<DollarCircleOutlined />} />
                      <StatisticCard label="Reports" value={reports} icon={<ExclamationCircleOutlined />} />
                      <StatisticCard label="Feedbacks" value={feedback} icon={<InboxOutlined />} />
                      <StatisticCard label="Contact Us" value={feedback} icon={<InboxOutlined />} />
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <SubDetailComponent />
              </Col>
              <Col lg="4">
                <Card className="card-chart">
                  <CardHeader>
                    <h5 className="card-category">Sales</h5>
                    <CardTitle tag="h3">
                      <i className="tim-icons icon-delivery-fast text-primary" />{" "}
                      3,500€
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Bar
                        data={chartExample3.data}
                        options={chartExample3.options}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="card-chart">
                  <CardHeader>
                    <h5 className="card-category">Some other spec?</h5>
                    <CardTitle tag="h3">
                      <i className="tim-icons icon-send text-success" /> 12,100K
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <div className="chart-area">
                      <Line
                        data={chartExample4.data}
                        options={chartExample4.options}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        ) :
          (
            <div className="content">
            <Row>

              <Col md="12">
                <Card className="card-plain">
                  <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                  }}>
                    <ThreeCircles
                      height="100"
                      width="100"
                      color="#9753f7"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="three-circles-rotating"
                      outerCircleColor=""
                      innerCircleColor="grey"
                      middleCircleColor=""
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          )

      }
    </>
  );
}

export default Dashboard;
