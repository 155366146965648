import React, { useState, useEffect } from 'react';
import { ThreeCircles } from 'react-loader-spinner'
import NotificationAlert from "react-notification-alert";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import "../../assets/css/black-dashboard-react.css"
import { firestoreKeys } from '../../utils/constants';
import { db } from "../../services/firebase";
import { collection, query, getDocs, doc, updateDoc } from "firebase/firestore";
import { DataGrid } from '@mui/x-data-grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";

export default function ContactUs({ user }) {
  const [IsLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([])
  const [showUpdate, setShowUpdate] = useState(false);
  const handleCloseUpdate = () => setShowUpdate(false);
  // Update Form
  const [bannerToUpdate, setContactToUpdate] = useState("");
  const [message, setMessage] = useState("")
  const [responded, setResponded] = useState(false);

  const handleResponded = (event) => {
    setResponded(event.target.checked);
  };


  const notificationAlertRef = React.useRef(null);
  const notify = (place, message, type) => {
    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const updateContact = async () => {
    const docRef = doc(db, firestoreKeys.FEEDBACK, bannerToUpdate.id);
    let updatedFields = { handled: responded, adminMessage: message }
    updateDoc(docRef, updatedFields)
      .then(docRef => {
        const tmpArray = items
        let indexToUpdate = tmpArray.find(item => item.id === bannerToUpdate.id);
        indexToUpdate.handled = responded
        indexToUpdate.adminMessage = message
        setItems(tmpArray)
        setShowUpdate(false)
      })
      .catch(error => {
        console.log(error);
        notify("tl", <p>{error}</p>, "danger")
        setShowUpdate(false)
      })


  }

  const editContact = async (contact) => {
    console.log("Using:",contact)
    setContactToUpdate(contact)
    setMessage(contact.adminMessage)
    setResponded(contact.handled)
    setShowUpdate(true)
  };

  const handledContact = async (id, handled) => {
    setIsLoaded(false)
    const docRef = doc(db, firestoreKeys.FEEDBACK, id);
    let updatedFields = { handled: handled }
    const date = Date()
    if (handled) {
      updatedFields.datePublished = date
    }
    updateDoc(docRef, updatedFields)
      .then(docRef => {
        const tmpArray = items
        let indexToUpdate = tmpArray.find(item => item.id === id);
        indexToUpdate.handled = handled;
        if (handled) {
          updatedFields.datePublished = date
        }
        setItems(tmpArray)
        setIsLoaded(true)
      })
      .catch(error => {
        console.log(error);
        notify("tl", <p>error</p>, "danger")
        setIsLoaded(true)
      })

  };



  useEffect(() => {
    const getContacts = async () => {
      const tmpArray = []
      const querySnapshot = await getDocs(query(collection(db, firestoreKeys.FEEDBACK)));
      querySnapshot.forEach((doc) => {
        console.log(doc.data())
        let item = doc.data()
        item.id = doc.id
        tmpArray.push(item)
      });
      setItems(tmpArray)
      console.log(items)
      setIsLoaded(true)
    }
    getContacts()
  }, [])

  const renderDetailsButton = (params) => {
    return (
      <>
        <Button
          variant="primary"
          color="primary"
          size="small"
          style={{ marginLeft: 16 }}
          onClick={() => {
            editContact(params.row)
          }}
        >
          Info
        </Button>
        {params.row.handled ? (
          <Button
            variant="primary"
            color="primary"
            size="small"
            onClick={() => {
              handledContact(params.row.id, false)
            }}
          >
            Unset
          </Button>
        ) : (
          <Button
            variant="primary"
            color="primary"
            size="small"
            onClick={() => {
              handledContact(params.row.id, true)
            }}
          >
            Responded
          </Button>
        )}

      </>
    )
  }


  const handledCheckBox = (params) => {
    return (
      <>
        {params.row.handled ? (
          <Checkbox defaultChecked />
        ) : (
          <Checkbox />
        )

        }


      </>
    )
  }

  const columns = [
    {
      field: 'title',
      headerName: 'Title',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      valueGetter: (params) =>
        `${params.row.title || ''}`,
    },
    {
      field: 'handled',
      headerName: 'Handled',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      renderCell: handledCheckBox,
      disableClickEventBubbling: true,
    },
    {
      field: 'datePublished',
      headerName: 'Last Updated',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      flex: 1,
      valueGetter: (params) =>
        `${params.row.datePublished || ''}`,
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: renderDetailsButton,
      disableClickEventBubbling: true,
    },
  ];



  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {IsLoaded ? (
        <>
          <>
            <div className="content">
              <Row>

                <Col md="12">
                  <Card className="card-plain">
                    <CardHeader>
                      <CardTitle tag="h4" style={{fontSize: "1.2rem"}}>Feedback</CardTitle>
                      <p className="category" style={{ fontSize: "0.8rem"}}>Feedback is information passed along from users.</p>
                    </CardHeader>
                    <CardBody>
                      <div style={{ height: 400, width: '100%' }}>
                        <DataGrid
                          rows={items}
                          columns={columns}
                          pageSize={5}
                          rowsPerPageOptions={[5]}
                          style={{}}
                          className="text-cente tablesorter"
                          getRowId={(row) => row.id}

                        /> </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
            {/* Update modal */}
            <Modal show={showUpdate} onHide={handleCloseUpdate}>
              <Modal.Header>
                <Modal.Title>Update Contact</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group >
                    <Form.Label>ID</Form.Label>
                    <Form.Control
                      type="id"
                      autoFocus
                      readOnly
                      value={bannerToUpdate.id}
                    />
                  </Form.Group>
                  <Form.Group >
                    <Form.Label style={{ marginTop: "2vh"}}>Last published</Form.Label>
                    <Form.Control
                      type="datePublished"
                      autoFocus
                      value={bannerToUpdate.datePublished}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group >
                    <Form.Label style={{ marginTop: "2vh"}}>Title</Form.Label>
                    <Form.Control
                      type="email"
                      autoFocus
                      readOnly
                      value={bannerToUpdate.title}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label style={{ marginTop: "2vh"}}>Message</Form.Label>
                    <Form.Control as="textarea" rows={3}readOnly  value={bannerToUpdate.message}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label style={{ marginTop: "2vh"}}>Admin Response</Form.Label>
                    <Form.Control as="textarea" rows={3} value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group style={{ marginLeft: "1vw"}}>
                    <FormControlLabel
                      label="Responded to"
                      control={<Checkbox checked={responded} onChange={handleResponded} />}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>

              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseUpdate} style={{ marginBottom: "3vh", marginLeft: "2vw"}}>
                  Close
                </Button>
                <Button variant="primary" onClick={updateContact} style={{ marginBottom: "3vh", marginRight: "2vw"}}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </>



        </>)
        : (
          <div className="content">
            <Row>

              <Col md="12">
                <Card className="card-plain">
                  <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                  }}>
                    <ThreeCircles
                      height="100"
                      width="100"
                      color="#9753f7"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="three-circles-rotating"
                      outerCircleColor=""
                      innerCircleColor="grey"
                      middleCircleColor=""
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>


        )
      }

    </>
  );
}

