import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import AdminLayout from "layouts/Admin/Admin.js";
import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ThreeCircles } from 'react-loader-spinner'
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import Login from "views/auth/Login";
import { AuthContext } from "../src/contexts/AuthContext";
import { db } from "./services/firebase";
import { doc, getDoc } from "firebase/firestore";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

// Initialize Firebase
const app = initializeApp(config);

export const auth = getAuth(app);
export default app;

const root = ReactDOM.createRoot(document.getElementById("root"));

Sentry.init({
  dsn: "https://c08cea86363145b8ac27c0eee5a6d2d0@o796007.ingest.sentry.io/4503989959196672",
  integrations: [new BrowserTracing()],
  environment: process.env.STAGE,
  tracesSampleRate: 1.0,
});

export function App() {
  const [IsLoaded, setIsLoaded] = useState(false);
  const [user, setUser] = useState(null);
  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    const getContacts = async () => {
      await auth.onAuthStateChanged(async (firebaseUser) => {
        setUser(firebaseUser);
        console.log("What?:",firebaseUser)
        if (firebaseUser !== null) {
          const docRef = doc(db, "users", firebaseUser.uid);
          getDoc(docRef).then(docSnap => {
            
            if (docSnap.exists()) {
              console.log("Perms are:",docSnap.data().permissions)
              if (!docSnap.data().permissions.admin || docSnap.data().permissions.admin === undefined) {
                Sentry.captureException("Non admin user: ", firebaseUser);
                window.location.href = process.env.REACT_APP_CUSOMTER;
              }
              setAdmin(docSnap.data())
              setIsLoaded(true)
            } else {
              Sentry.captureException("No such document for user: ", firebaseUser);
              window.location.href = process.env.REACT_APP_CUSOMTER;
            }
          }).catch(err => {
            Sentry.captureException("No such document for user: ", err);
            window.location.href = process.env.REACT_APP_CUSOMTER;
          })
        } else {
          setAdmin(false)
          setIsLoaded(true)
        }
      });

    }
    getContacts()
  }, [])

  return (<>
    <AuthContext.Provider value={{ user, setUser }}>

      <>
        {
          IsLoaded ? (
            <>
              {
                user !== null ? (
                  <>

                    {
                      admin.permissions.admin && (
                        <>
                          <ThemeContextWrapper>
                            <BackgroundColorWrapper>
                              <BrowserRouter>
                                <Switch>
                                  <Route path="/" render={(props) => <AdminLayout {...props} />} />
                                </Switch>
                              </BrowserRouter>
                            </BackgroundColorWrapper>
                          </ThemeContextWrapper>
                        </>)}
                  </>)
                  : (
                    <ThemeContextWrapper>
                      <BackgroundColorWrapper>
                        <BrowserRouter>
                          <Switch>
                            <Route path="/*" render={(props) => <Login {...setUser} />} />
                          </Switch>
                        </BrowserRouter>
                      </BackgroundColorWrapper>
                    </ThemeContextWrapper>
                  )
              }

            </>)
            : (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                backgroundColor: "white"
              }}>
                <ThreeCircles
                  height="100"
                  width="100"
                  color="#9753f7"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="three-circles-rotating"
                  outerCircleColor=""
                  innerCircleColor="grey"
                  middleCircleColor=""
                /></div>

            )
        }
      </>
    </AuthContext.Provider>
  </>

  );
}

root.render(
  <App />
);

